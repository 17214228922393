<template>
  <div class="px-2 py-2 d-flex justify-content-start align-items-start">
    <span
      class="label label-dot mr-4 mt-5"
      :class="calculatedUserStatus"
      v-b-tooltip.hover.left
      :title="calculatedUserTooltip"
    >
    </span>
    <UserThumbnail
      :canOpenProfile="currentUser.menu_items.includes('AdminProjectUsers')"
      :user="user"
      @open_profile_modal="$emit('open_profile_modal', $event)"
      class="mt-1"
    >
    </UserThumbnail>
    <div class="pl-4">
      <span
        class="font-weight-bold pl-1 d-block"
        style="font-size: .925rem"
      >
        {{ user.display_name }}
      </span>
      <span
        v-if="user.projectRelation.link.external_reference != null"
        class="font-weight-semibold pl-1 d-block"
        style="font-size: .85rem"
      >
        Reference: {{ user.projectRelation.link.external_reference }}
      </span>
      <div v-if="user.project_segments.length > 0" class="pt-1 d-block">
        <template v-for="(segment, sg) in user.project_segments">
          <span
            :key="sg"
            class="segment-pill label label-pill label-inline mr-2 label-outline-primary font-weight-bold"
          >
            {{ segment.name }}
          </span>
        </template>
      </div>

      <span
        class="text-muted font-weight-bold pl-1 d-block"
        :class="(user.project_segments.length > 0) ? 'pt-2' : 'pt-1'"
        style="font-size:.85rem"
      >
        {{ user.user_sample.label }}
      </span>

      <div class="text-muted d-block pl-1" style="font-size: 0.85rem;">
        Last Login:
        <span v-if="user.project_login == null"> {{ $t("TABLES.PROJECT_USER_TOOLTIPS.NEVER") }} </span>
        <GenericDatetimeDisplay
          v-else
          :date="user.project_login"
          :lastUpdated="true"
          :relative="true"
        >
        </GenericDatetimeDisplay>          
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import UserThumbnail from "@/modules/together-helpers/components/generic-displays/GenericUserThumbnail";
import GenericDatetimeDisplay from "@/modules/together-helpers/components/generic-displays/GenericDatetimeDisplay"

export default {
  name: "UserSummary", 

  props: {
    user: { type: Object, required: true }
  },

  components: {
    UserThumbnail,
    GenericDatetimeDisplay
  },

  computed: {
    ...mapGetters([
      "currentUser"
    ]),
    calculatedUserStatus: function() {
      switch (this.user.projectRelation.setup) {
        case 1:
          return "label-dark"
        case 2:
          return "label-secondary"
        case 3:
        default:
          return "label-warning";
        case 4:
          return "label-success";
        case 5:
          return "label-danger";
      }
    },
    calculatedUserTooltip: function() {
      switch (this.user.projectRelation.setup) {
        case 1:
          return `${this.$t('GENERAL.STATUS.SYSTEM_BLOCKED')}`;
        case 2:
          return `${this.$t('TABLES.PROJECT_USER_TOOLTIPS.NO_INVITE')}`
        case 3:
        default:
          return `${this.$t('TABLES.PROJECT_USER_TOOLTIPS.INCOMPLETE')}`
        case 4:
          return `${this.$t('TABLES.PROJECT_USER_TOOLTIPS.COMPLETE')}`
        case 5:
          return `${this.$t('TABLES.PROJECT_USER_TOOLTIPS.PROJECT_BLOCKED')}`;
      }
    },
  }
}
</script>