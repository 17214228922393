class FilterService {

  title = null;
  filterConfig = null
  options = {}
  data = {}
  reset = {}

  constructor(
    filterConfig,
    data
  ) {
    this.filterConfig = filterConfig;
    this.data = data;
    this.reset = JSON.parse(JSON.stringify(data));
  }

  setDataValue(dataKey, dataValue) {
    this.data[dataKey] = dataValue
  }

  resetData() {
    this.data = JSON.parse(JSON.stringify(this.reset));
  }

}

export default FilterService;