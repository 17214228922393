<template>
  <div class="col-xl-12 px-0">
    <div
      class="card card-custom card-stretch gutter-b"
      :class="error.active ? 'bordered-card-danger' : 'bordered-card-primary'"
    >
      <div class="card-body">
        <b-spinner
          v-if="loadingComplete == false && !error.active"
          variant="primary"
          label="Spinning"
          class="card-loader"
        >
        </b-spinner>
        <div
          v-if="loadingComplete && !error.active && userEngagementData != null"
          class="table-responsive"
          style="max-height: 75vh"
        >
          <table
            id="activity_engagement_table"
            class="table table-head-custom table-vertical center table-hover"
            style="table-layout:auto; overflow: auto"
          >
            <thead style="position:sticky; top:0px; z-index: 2">
              <tr>
                <th
                  data-sticky='true'
                  rowspan="2"
                  class="px-2 py-3 text-center"
                  style="width:60px; min-width:60px; left:0px"
                >
                  <b-form-checkbox id="checkAllComponent" v-model="checkAll" size="lg"></b-form-checkbox>
                </th>
					      <th
                  data-sticky='true'
                  rowspan="2"
                  class="px-2 pb-4"
                  style="width:325px; min-width: 325px; left:60px; cursor: pointer"
                  @click="applySorting('displayName')"
                >
                  <div class="d-flex justify-content-start align-items-center w-100">
                    {{ `${$t('TABLES.USER_ENGAGEMENT_HEADER.PROFILE')} (${userEngagementData.userData.length})` }}
                    <i
                      v-if="sorting.displayName != null"
                      class="fa fa-solid pl-2"
                      :class="(sorting.displayName == 'ASC') ? 'fa-caret-up' : 'fa-caret-down'"
                      style="font-size: .925rem"
                    />
                  </div>
                </th>
					      <th
                  data-sticky='true'
                  rowspan="2"
                  class="px-2 pb-4"
                  style="width:200px; min-width: 200px; left:385px; cursor: pointer"
                  @click="applySorting('engagementScore')"
                >
                  <div class="d-flex justify-content-start align-items-center w-100">
                    {{ $t('TABLES.USER_ENGAGEMENT_HEADER.PARTICIPATION') }}
                    <i
                      v-if="sorting.engagementScore != null"
                      class="fa fa-solid pl-2"
                      :class="(sorting.engagementScore == 'ASC') ? 'fa-caret-up' : 'fa-caret-down'"
                      style="font-size: .925rem"
                    />                    
                  </div>
                </th>
					      <th
                  data-sticky='true'
                  class="px-2 pb-4 dropdown text-center activity-filter-header"
                  :class="(checkedActivityIds.length > 0) ? 'active' : null"
                  style="min-width:400px; left: 585px; z-index: 1 !important"
                  :colspan="(checkedActivityIds.length <= 6) ? checkedActivityIds.length : 6"
                >
                  <ActivitiesHeader
                    :checkedActivityIds="checkedActivityIds"
                    :userEngagementData="userEngagementData"
                    :showActivitySelect="showActivitySelect"
                    @toggle-activity-select="showActivitySelect = $event"
                    @toggle-activity="onToggleActivity($event)"
                    @toggle-all="onToggleAllActivities()"
                    @clear-activities="checkedActivityIds = []"
                  />
                </th>
                <th
                  v-if="checkedActivityIds.length > 6"
                  class="px-2 pb-4 activity-filter-header"
                  :class="(checkedActivityIds.length > 0) ? 'active' : null"
                  :colspan="checkedActivityIds.length - 6"
                />
              </tr>
              <tr v-if="checkedActivityIds.length > 0">
                <template v-for="(activity, a) in checkedActivitySummaries">
                  <th
                    :key="a"
                    class="activity-header text-center"
                    style="cursor: pointer"
                    @click="applyActivitySorting(activity.id)"
                  >
                    <div
                      class="d-flex justify-content-center align-items-center w-100"
                      :class="{ 'px-2' : sorting.activity_id != activity.id }"
                    >
                      {{ `${activity.shortened_activity_title} (${activity.project_engagement.completed}/${activity.project_engagement.eligible})` }}
                      <i
                        v-if="sorting.activity.id == activity.id"
                        class="fa fa-solid pl-2"
                        :class="(sorting.activity.dir == 'ASC') ? 'fa-caret-up' : 'fa-caret-down'"
                        style="font-size: .925rem"
                      />                    
                    </div>
                  </th>
                </template>
              </tr>
            </thead>

            <tbody>
              <template v-for="(user, u) in computedUserData">
                <UserEngagementRow
                  :ref="`user-row-${user.id}`"
                  :key="u"
                  :user="user"
                  :activities="checkedActivitySummaries"
                  :hoveringColumn="hoveringColumn"
                  @user_checked="$emit('user_checked',$event)"
                  @user_unchecked="$emit('user_unchecked',$event)"
                  @hover-row="hoveringColumn = $event"
                  @open_profile_modal="openProfileModal"
                />
              </template>
            </tbody>
          </table>

          <UserProjectProfile
            :userId="userId"
            ref="userProjectProfileModal"
          >
          </UserProjectProfile>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UserEngagementRow from "@/view/components/table-rows/UserEngagementRow";
import ActivitiesHeader from "@/view/components/table-headers/user_engagement/ActivitiesHeader";
import UserProjectProfile from "@/view/content/modals/UserProjectProfile";

export default {
  name: "UserEngagementTable",

  props: {
    error: { type: Object, required: true },
    loadingComplete: { type: Boolean, required: true },
    userEngagementData: { type: Object, required: false, default: null }
  },

  data() {
    return {
      userId: null,
      showActivitySelect: false,
      checkAll: false,
      checkedActivityIds: [],
      hoveringColumn: null,
      sorting: {
        displayName: null,
        engagementScore: null,
        activity: {
          id: null,
          dir: null
        }
      }
    }
  },

  components: {
    UserEngagementRow,
    ActivitiesHeader,
    UserProjectProfile
  },

  methods: {
    onToggleActivity: function(activityId) {
      if (this.checkedActivityIds.includes(activityId)) {
        this.checkedActivityIds = this.checkedActivityIds.filter(function(checkedActivityId) { return checkedActivityId != activityId });
      } else {
        this.checkedActivityIds.push(activityId);
      }
    },
    onToggleAllActivities: function() {
      let allActivityIds = this.userEngagementData.activityData.map(a => a.id);

      if (this.checkedActivityIds.length < allActivityIds.length) {
        this.checkedActivityIds = allActivityIds;
      } else {
        this.checkedActivityIds = [];
      }
    },
    openProfileModal: function(val) {
      this.userId = val;
      setTimeout(() => {
        this.$refs.userProjectProfileModal.$refs.modal.show();
      }, 100);
    },
    applySorting: function(metric) {
      let states = [null, 'DESC', 'ASC'];
      let currentIndex = states.indexOf(this.sorting[metric]);
      this.sorting[metric] = ((currentIndex + 1) >= states.length) ? states[0] : states[(currentIndex + 1)];

      const self = this;
      for (const [key] of Object.entries(this.sorting)) {
        if (key != metric && ['displayName','engagementScore'].includes(key)) {
          self.sorting[key] = states[0]
        }
      }

      this.sorting.activity.id = null;
      this.sorting.activity.dir = null;
    },
    applyActivitySorting: function(activityId) {
      this.sorting.engagementScore = null;
      this.sorting.displayName = null;

      if (this.sorting.activity.id != activityId) {
        this.sorting.activity.id = activityId;
        this.sorting.activity.dir = 'DESC';
      } else if (this.sorting.activity.dir == 'DESC') {
        this.sorting.activity.dir = 'ASC';
      } else if (this.sorting.activity.dir == 'ASC') {
        this.sorting.activity.id = null;
        this.sorting.activity.dir = null;
      }
    }
  },

  watch: {
    checkAll: function(newValue) {
      const self = this;
      this.userEngagementData.userData.forEach(function(user) {
        if (newValue === true) {
          self.$refs[`user-row-${user.id}`][0].checkRow();
        } else {
          self.$refs[`user-row-${user.id}`][0].uncheckRow();
        }
      })
    },
    checkedActivityIds: function(newValue) {
      if (newValue.length == 0) {
        this.sorting.activity.id = null;
        this.sorting.activity.dir = null;
      }
    }
  },

  computed: {
    checkedActivitySummaries: function() {
      const self = this;
      return this.userEngagementData.activityData.filter(function(activity) {
        return self.checkedActivityIds.includes(activity.id);
      })
    },
    computedUserData: function() {
      let baseData = this.userEngagementData.userData;
      let refinedData = [];

      if (this.sorting.engagementScore != null) {
        if (this.sorting.engagementScore == 'ASC') {
          refinedData = baseData.sort((a,b) => (a.engagement_score > b.engagement_score) ? 1 : ((b.engagement_score > a.engagement_score) ? -1 : 0))
        } else if (this.sorting.engagementScore == 'DESC') {
          refinedData = baseData.sort((a,b) => (a.engagement_score < b.engagement_score) ? 1 : ((b.engagement_score < a.engagement_score) ? -1 : 0))
        }
      } else if (this.sorting.activity.id != null) {
        let states = (this.sorting.activity.dir == 'DESC') ? ['completed','progress','not_started'] : ['not_started','progress','completed'];
        const self = this;

        states.forEach(function(state) {
          let matchingUsers = self.userEngagementData.userData.filter(function(user) {
            return user.project_engagement[state].includes(self.sorting.activity.id);
          });

          matchingUsers.sort((a,b) => (a.display_name > b.display_name) ? 1 : ((b.display_name > a.display_name) ? -1 : 0))

          matchingUsers.forEach(function(user) {
            refinedData.push(user);
          })
        })

        let notEligibleUers = this.userEngagementData.userData.filter(function(user) {
          return !user.project_engagement['eligible'].includes(self.sorting.activity.id);
        });

        notEligibleUers.forEach(function(user) {
          refinedData.push(user);
        })
      } else {
        if (this.sorting.displayName == null || this.sorting.displayName == 'DESC') {
          refinedData = baseData.sort((a,b) => (a.display_name > b.display_name) ? 1 : ((b.display_name > a.display_name) ? -1 : 0))
        } else if (this.sorting.displayName == 'ASC') {
          refinedData = baseData.sort((a,b) => (a.display_name < b.display_name) ? 1 : ((b.display_name < a.display_name) ? -1 : 0))
        }
      }

      return refinedData;
    }
  }
}
</script>