<template>
  <div
    class="px-2"
    :class="(!condensed) ? 'd-flex justify-content-between py-4' : 'py-0'"
  >
    <div
      class="py-2"
      :class="(condensed) ? 'd-flex align-items-center justify-content-start' : 'd-inline-flex pr-2'"
    >
      <i
        class="fas fa-check-circle pr-1 text-success"
      />
      <span style="font-size:.85rem">{{ data.completed.length }}/{{ data.eligible.length }} {{ $t('TABLES.USER_ENGAGEMENT_SUMMARY.COMPLETED') }}</span>
    </div>

    <div
      class="py-2"
      :class="(condensed) ? 'd-flex align-items-center justify-content-start' : 'd-inline-flex px-2'"
    >
      <i
        class="fas fa-dot-circle pr-1 text-warning"
      />
      <span style="font-size:.85rem">{{ data.progress.length }}/{{ data.eligible.length }} {{ $t('TABLES.USER_ENGAGEMENT_SUMMARY.IN_PROGRESS') }}</span>
    </div>

    <div
      class="py-2"
      :class="(condensed) ? 'd-flex align-items-center justify-content-start' : 'd-inline-flex pl-2'"
    >
      <i
        class="fas fa-stop-circle pr-1 text-danger"
      />
      <span style="font-size:.85rem">{{ data.not_started.length }}/{{ data.eligible.length }} {{ $t('TABLES.USER_ENGAGEMENT_SUMMARY.NOT_STARTED') }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "ParticipationSummary",

  props: {
    condensed: { type: Boolean, required: true },
    data: { type: Object, required: true }
  }
}
</script>