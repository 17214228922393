<template>
  <tr>
    <td
      data-sticky='true'
      class="px-2 py-4 text-center"
      style="left: 0px"
    >
      <b-form-checkbox
        v-model="rowChecked"
        size="lg"
        class="mt-3"
      >
      </b-form-checkbox>
    </td>

    <td
      data-sticky='true'
      style="left: 60px"
    >
      <UserSummary
        :user="user"
        @open_profile_modal="$emit('open_profile_modal',$event)"
      />
    </td>

    <td
      data-sticky='true'
      class="align-middle"
      style="left: 385px"
      :style="`left: 385px; ${(activities.length > 0) ? 'border-right: 1px solid #f0f1f2 !important' : null}`"
    >
      <ParticipationSummary
        :condensed="true"
        :data="user.project_engagement"
      />
    </td>

    <td
      v-if="activities.length == 0"
    />

    <template v-for="(activity, a) in activities">
      <ActivitySummary
        :key="a"
        :activityId="activity.id"
        :engagement="user.project_engagement"
        :index="a"
        :hoveringColumn="hoveringColumn"
        :activityCount="activities.length"
        @hover-row="$emit('hover-row', $event)"
      />
    </template>
  </tr>
</template>

<script>
import UserSummary from "@/view/components/table-columns/user_engagement/UserSummary";
import ParticipationSummary from "@/view/components/table-columns/user_engagement/ParticipationSummary";
import ActivitySummary from "@/view/components/table-columns/user_engagement/ActivitySummary";

export default {
  name: "UserEngagementRow",

  props: {
    user: { type: Object, required: true },
    activities: { type: Array, required: true },
    hoveringColumn: { type: Number, required: false }
  },

  data() {
    return {
      rowChecked: false
    }
  },

  components: {
    UserSummary,
    ParticipationSummary,
    ActivitySummary
  },

   methods: {
    checkRow: function() {
      this.rowChecked = true;
    },
    uncheckRow: function() {
      this.rowChecked = false;
    }
   },

  watch: {
    rowChecked: function(newValue) {
      (newValue == true) ? this.$emit('user_checked',this.user.id) : this.$emit('user_unchecked',this.user.id);
    }
  },
}
</script>