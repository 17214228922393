<template>
  <div
    class="dropdown"
    ref='custom-dropdown'
  >
    <button
      class="btn btn-sm dropdown-toggle"
      :class="(state != null) ? state : null"
      type="button"
      @click="actionDropdown = (actionDropdown === true) ? false : true"
      :disabled="disabled"
    >
      {{ buttonText }}
    </button>
    <div
      class="dropdown-menu"
      :class="{ 'show' : actionDropdown }"
      aria-labelledby="dropdownMenuButton"
      style="width:max-content; max-width: 600px"
    >
      <slot name="actions" />
    </div>
  </div>
</template>

<script>
import { onClickOutside } from '@vueuse/core'

export default {
  name: "VueCompatibleDropdown",

  props: {
    buttonText: { type: String, required: false, default: "" },
    state: { type: String, required: false, default: null },
    disabled: { type: Boolean, required: false, default: false }
  },

  data() {
    return {
      actionDropdown: false
    }
  },

  mounted() {
    onClickOutside(this.$refs['custom-dropdown'], () => this.actionDropdown = false)
  }
}
</script>