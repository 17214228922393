<template>
  <div class="card-toolbar">
    <b-dropdown
      id="user-engagement-dropdown"
      class="mr-2"
      :text="$t('TABLES.PROJECT_USER_NAVS.SELECTED') + ' (' + checkedUsersCount.toString() + ')'"
      size="sm"
      variant="primary"
      :disabled="checkedUsersCount == 0"
    >
      <!--start::Block Users-->
      <BlockUsers
        :checkedUsers="checkedUsers"
        @users_changed="emitUserChange"
      >
      </BlockUsers>
      <!--end::Block Users-->

      <!--start::Unblock Users-->
      <UnblockUsers
        :checkedUsers="checkedUsers"
        @users_changed="emitUserChange"
      >
      </UnblockUsers>
      <!--end::Unblock Users-->

      <!--start::Add To Segment-->
      <AddSegment
        :checkedUsers="checkedUsers"
        :segmentChoices="options"
        @users_changed="$emit('users_changed')"
      >
      </AddSegment>
      <!--end::Add To Segment-->

      <!--start::Remove From Segment-->
      <RemoveSegment
        :checkedUsers="checkedUsers"
        :segmentChoices="options"
        @users_changed="$emit('users_changed')"
      >
      </RemoveSegment>
      <!--end::Remove From Segment-->

      <!--start::Send Communication-->
      <b-dropdown-item
        @click="sendCommunication"
      >
        {{ $t('TABLES.USER_ENGAGEMENT_TOOLBAR.SEND_COMMUNICATION') }}
      </b-dropdown-item>
      <!--end::Send Communication-->

      <!--start::View Posts-->
      <b-dropdown-item
        @click="viewPosts"
      >
        {{ $t('TABLES.USER_ENGAGEMENT_TOOLBAR.VIEW_POSTS') }}
      </b-dropdown-item>
      <!--end::View Posts-->
    </b-dropdown>
  </div>
</template>

<script>
import {
  mapMutations
} from "vuex";
import { POPULATE_FILTER_LIST } from "@/core/services/store/project/project_stream.module";

import BlockUsers from "@/view/pages/user_management/project_user/toolbar_options/BlockUsers";
import UnblockUsers from "@/view/pages/user_management/project_user/toolbar_options/UnblockUsers";
import AddSegment from "@/view/pages/user_management/project_user/toolbar_options/AddToSegment";
import RemoveSegment from "@/view/pages/user_management/project_user/toolbar_options/RemoveFromSegment";

export default {
  name: "UserEngagementToolbar",

  props: {
    checkedUsers: { type: Array, required: true },
    segmentations: { type: Array, required: true },
    users: { type: Array, required: true }
  },

  data() {
    return {
      options: {}
    }
  },

  components: {
    BlockUsers,
    UnblockUsers,
    AddSegment,
    RemoveSegment
  },

  mounted() {
    const self = this;
    this.segmentations.forEach(function (segmentation) {
      self.options[segmentation.name] = {};
      segmentation.project_segments.forEach(function (segment) {
        self.options[segmentation.name][segment.id] = segment.name;
      })
    });
  },

  methods: {
    sendCommunication: function() {
      const self = this;
      this.setProjectCommunicationPreload({
        users: self.checkedUsers
      });

      this.$router.push({ name: 'manage_communication' });
    },
    viewPosts: function() {
      const self = this;
      let filteredUsers = this.users.filter(function(user) {
        return self.checkedUsers.includes(user.id)
      });

      const streamUsers = filteredUsers.map(obj => {
        return { id: obj.id, display: obj.display_name };
      });

      this.$store
        .dispatch(POPULATE_FILTER_LIST, { participants: streamUsers, preLoading: 1 })
        .then(() => {
          this.$router.push({ name: 'stream'})
        })
    },
    emitUserChange: function() {
      this.$emit('users_changed');
    },
    ...mapMutations({
      setProjectCommunicationPreload: "setProjectCommunicationPreload"
    })
  },

  computed: {
    checkedUsersCount: function() {
      return this.checkedUsers.length;
    }
  }
}
</script>