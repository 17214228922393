<template>
  <td
    class="align-middle text-center"
    :class="{ 'hover' : hoveringColumn == index && activityCount >= 3 }"
    @mouseover="$emit('hover-row', index)"
    @mouseleave="hover = $emit('hover-row', null)"
  >
    <div class="py-1 d-flex align-items-center justify-content-center">
      <i
        :class="`${activitySummary.icon} pr-1`"
        v-b-tooltip.hover.left
        :title="activitySummary.text"
      />
    </div>
  </td>
</template>

<script>
export default {
  name: "ActivitySummary",

  props: {
    activityId: { type: Number, required: true },
    engagement: { type: Object, required: true },
    index: { type: Number, required: true },
    hoveringColumn: { type: Number, required: false },
    activityCount: { type: Number, required: true }
  },

  computed: {
    activitySummary: function() {
      if (this.engagement.completed.includes(this.activityId)) {
        return {
          icon: 'fas fa-check-circle text-success',
          text: `${this.$t("TABLES.USER_ENGAGEMENT_SUMMARY.COMPLETED")}`
        }
      } else if (this.engagement.not_started.includes(this.activityId)) {
        return {
          icon: 'fas fa-stop-circle text-danger',
          text: `${this.$t("TABLES.USER_ENGAGEMENT_SUMMARY.NOT_STARTED")}`
        }
      } else if (this.engagement.progress.includes(this.activityId)) {
        return {
          icon: 'fas fa-dot-circle text-warning',
          text: `${this.$t("TABLES.USER_ENGAGEMENT_SUMMARY.IN_PROGRESS")}`
        }
      } else {
        return {
          icon: 'fas fa-eye-slash text-dark',
          text: `${this.$t("TABLES.USER_ENGAGEMENT_SUMMARY.NOT_ELIGIBLE")}`
        }
      }
    }
  }
}
</script>