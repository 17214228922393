<template>
  <div
    class="dropdown ts-filter-dropdown"
    :class="{ 'selected' : model == 1 }"
    style="margin-left: 15px;"
  >
    <button
      class="btn btn-sm dropdown-toggle"
      type="button"
      @click="model = (model == 1) ? 0 : 1"
    >
      {{ title }}
    </button>
  </div>
</template>

<script>
import Filter from "@/core/services/FilterService";

export default {
  name: "ToggleInput",

  props: {
    id: { type: String, required: true },
    title: { type: String, required: false, default: "" },
    filterService: { type: Filter, required: true }
  },

  data() {
    return {
      model: ""
    }
  },

  watch: {
    model: {
      handler(newValue) {
        if (newValue != this.filterValue) {
          this.filterService.setDataValue(this.id, newValue);
        }
      }
    },
    filterValue: {
      handler(newValue) {
        if (newValue != this.model) {
          this.model = newValue;
        }
      },
      immediate: true
    }
  },

  computed: {
    filterValue: function() {
      return this.filterService.data[this.id];
    }
  }
}
</script>