<template>
  <Dropdown
    :buttonText="calculatedTitle"
    class="ts-filter-dropdown"
    :class="{ 'selected' : model.length > 0 }"
  >
    <template v-slot:actions>
      <div class="p-3">
        <div
          v-if="scope != null"
          class="d-flex justify-content-center mx-5 mb-3"
        >
          <span
            v-for="(scopeOption, s) in scope.options"
            :key="s"
            class="px-1 btn btn-sm py-1 mx-1"
            :class="{ 'btn-primary' : scopeModel == scopeOption.id }"
            style="font-size:.8rem"
            @click="scopeModel = scopeOption.id"
          >
            {{ scopeOption.name }}
          </span>
        </div>

        <input
          type="text"
          class="form-control form-control-solid py-2 mb-3"
          placeholder="search..."
          :style="`font-size:.9rem`"
          v-model="search"
        >
        <vuescroll>
          <div class="py-2" style="max-height:220px">
            <template v-for="(option, o) in calculatedOptions">
              <div :key="o" class="form-check mb-0 pl-0">

                <label
                  class="checkbox checkbox-square"
                >
                  <input
                    :id="`${id}-${o}`"
                    type="checkbox"
                    :checked="(model.includes(option[value]) && children == null) || (children != null && allChildrenChecked(option))"
                    @click="onToggle('parent',option)"
                  />
                  <span></span>
                  <p class="pl-2 mb-0">
                    {{ option[label] }}
                  </p>
                </label>

                <div v-if="children != null" class="ml-5 mb-3" >
                  <template
                    v-for="(childOption, co) in option[children.values]"
                  >
                    <label
                      v-show="childInSearch(childOption[children.name])"
                      :key="co"
                      class="checkbox checkbox-square"
                    >
                      <input
                        :id="`${id}-child-${o}-${co}`"
                        type="checkbox"
                        :checked="model.includes(childOption[children.id])"
                        @click="onToggle('child',childOption)"
                      />
                      <span></span>
                      <p class="pl-2 mb-0" :for="`${id}-child-${o}-${co}`">
                        {{ childOption[children.name] }}
                      </p>
                    </label>
                  </template>
                </div>
              </div>
            </template>
          </div>
        </vuescroll>
        <div
          v-if="model.length > 0"
          class="text-center w-100 mt-3"
        >
          <a
            href="javascript:void(0)"
            @click="model = []"
          >
            Reset
          </a>          
        </div>
      </div>

    </template>
  </Dropdown>
</template>

<script>
import Filter from "@/core/services/FilterService";
import Dropdown from "@/view/components/misc/Dropdown.vue";
import vuescroll from 'vuescroll';

export default {
  name: "FilterTreeSelect",

  props: {
    id: { type: String, required: true },
    title: { type: String, required: false },
    plural_title: { type: String, required: false, default: null },
    options: { required: false, default: [] },
    scope: { required: false, default: null },
    children: { required: false, default: null },
    filterService: { type: Filter, required: true },
    value: { type: String, required: false, default: "id" },
    label: { type: String, required: false, default: "name" },
    parentForChild: { type: Boolean, required: false, default: false }
  },

  data() {
    return {
      model: [],
      scopeModel: null,
      search: ""
    }
  },

  components: {
    Dropdown,
    vuescroll
  },

  methods: {
    onToggle: function(type, option) {
      let id = (type == 'parent') ? option[this.value] : option[this.children.id];
      let childOptions = [];
      if (type == 'parent' && this.children != null) {
        childOptions = option[this.children.values];
      }

      if (
        (type == 'child' && this.model.includes(id)) ||
        (type == 'parent' && this.children == null && this.model.includes(id)) ||
        (type == 'parent' && this.children != null && this.parentForChild === true && (childOptions == null || childOptions.length == 0) && this.model.includes(id))
      ) {
        this.model = this.model.filter(function(selected) { return selected != id });
      } else if (
        (type == 'parent' && this.children != null && this.allChildrenChecked(option) && (childOptions != null && childOptions.length > 0))
      ) {
        let childOptionIds = option[this.children.values].map(a => a[this.children.id]);
        this.model = this.model.filter(function(selectedOption) {
          return !childOptionIds.includes(selectedOption);
        })
      } else if (
        (type == 'child' && !this.model.includes(id)) ||
        (type == 'parent' && this.children == null && !this.model.includes(id)) ||
        (type == 'parent' && this.children != null && this.parentForChild === true && (childOptions == null || childOptions.length == 0) && !this.model.includes(id))
      ) {
        this.model.push(id);
      } else if (
        (type == 'parent' && this.children != null && !this.allChildrenChecked(option) && (childOptions != null && childOptions.length > 0))
      ) {
        let childOptionIds = option[this.children.values].map(a => a[this.children.id]);
        let newData = this.model.concat(childOptionIds);
        this.model = [...new Set(newData)];
      }
    },
    allChildrenChecked: function(parentOption) {
      if (parentOption[this.children.values] != null && parentOption[this.children.values].length > 0) {
        let childOptionIds = parentOption[this.children.values].map(a => a[this.children.id]);

        let matchingSelectedOptions = this.model.filter(function(selectedOption) {
          return childOptionIds.includes(selectedOption);
        })

        return ((childOptionIds.length == matchingSelectedOptions.length) && matchingSelectedOptions.length > 0) ? true : false;
      } else {
        return false;
      }
    },
    childInSearch: function(childLabel) {
      return (this.search.length == 0 || (this.search.length > 0 && childLabel.toLowerCase().includes(this.search.toLowerCase()))) ? true : false;
    }
  },

  watch: {
    model: {
      handler(newValue) {
        if (newValue != this.filterValue) {
          this.filterService.setDataValue(this.id, newValue);
        }
      },
      deep: true
    },
    scopeModel: {
      handler(newValue) {
        if (newValue != this.filterScopeValue && this.scope != null) {
          this.filterService.setDataValue(this.scope.id, newValue);
        }
      }
    },
    filterValue: {
      handler(newValue) {
        if (newValue != this.model) {
          this.model = newValue;
        }
      },
      deep: true,
      immediate: true
    },
    filterScopeValue: {
      handler(newValue) {
        if (newValue != this.scopeModel) {
          this.scopeModel = newValue;
        }
      },
      immediate: true
    }
  },

  computed: {
    calculatedOptions: function() {
      let options = [];

      if (typeof this.options == 'string') {
        options = this.filterService.options[this.options];
      } else {
        options = this.options;
      }

      const self = this;

      if (this.search.length > 0 && this.children == null) {
        return options.filter(function(option) {
          return option[self.label].toLowerCase().includes(self.search.toLowerCase());
        })
      } else if (this.search.length > 0 && this.children != null) {
        return options.filter(function(option) {
          return (
            option[self.label].toLowerCase().includes(self.search.toLowerCase()) ||
            option[self.children.values].filter(function(childOption) {
              return childOption[self.children.name].toLowerCase().includes(self.search.toLowerCase());
            }).length > 0
          );
        })
      } else {
        return options;
      }
    },
    filterValue: function() {
      return this.filterService.data[this.id];
    },
    filterScopeValue: function() {
      if (this.scope != null) {
        return this.filterService.data[this.scope.id];
      } else {
        return null;
      }
    },
    calculatedTitle: function() {
      if (this.model.length == 0) {
        return this.title;
      } else {
        const self = this;

        if (this.model.length == 1 && this.children == null) {
          let selectedValue = this.calculatedOptions.filter(function(option) {
            return option[self.value] == self.model[0]
          });

          return selectedValue[0][this.label];
        } else if (this.model.length == 1 && this.children != null && this.parentForChild === false) {
          let selectedParent = this.calculatedOptions.filter(function(option) {
            return option[self.children.values].filter(function(childOption) {
              return self.model.includes(childOption[self.children.id]);
            }).length > 0
          })[0];

          let firstAvailableValue = selectedParent[this.children.values].filter(function(childOption) {
            return self.model.includes(childOption[self.children.id]);
          })[0];

          return `${firstAvailableValue[this.children.name]}`
        } else {
          if (this.model.length == 1) {
            return `${this.model.length} ${this.title}`;
          } else {
            return `${this.model.length} ${(this.plural_title != null) ? this.plural_title : this.title}`;
          }
        }
      }
    }
  }
}
</script>