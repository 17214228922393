<template>
  <input
    type="text"
    class="form-control form-control-solid"
    :placeholder="title"
    :style="`font-size:.9rem; width:260px`"
    v-model="model"
  >
</template>

<script>
import Filter from "@/core/services/FilterService";

export default {
  name: "FilterTextInput",

  props: {
    id: { type: String, required: true },
    title: { type: String, required: false, default: "" },
    filterService: { type: Filter, required: true }
  },

  data() {
    return {
      model: ""
    }
  },

  watch: {
    model: {
      handler(newValue) {
        if (newValue != this.filterValue) {
          this.filterService.setDataValue(this.id, newValue);
        }
      }
    },
    filterValue(newValue) {
      if (newValue != this.model) {
        this.model = newValue;
      }
    },
    immediate: true
  },

  computed: {
    filterValue: function() {
      return this.filterService.data[this.id];
    }
  }
}
</script>