<template>
  <OnClickOutside @trigger="closePopover">
    <a 
      id="activity-header-popover-select"
      href="javascript:void(0)"
      role="button"
      data-toggle="dropdown"
      aria-haspopup="true"
      :aria-expanded="showActivitySelect"
      @click="$emit('toggle-activity-select',!showActivitySelect)"
    >
      {{ $t('TABLES.USER_ENGAGEMENT_HEADER.ACTIVITY') }}
      ({{ checkedActivityIds.length }}/{{ userEngagementData.activityData.length }})
      <i class="fas fa-cog pl-2" />
    </a>

    <b-popover
      id="activity-header-popover"
      :show.sync="showActivitySelect"
      title="Popover"
      placement="bottom"
      target="activity-header-popover-select"
    >
      <template #title>
        <a
          id="activity-select-all"
          class="dropdown-item px-4 py-4 mb-0"
          href="javascript:void(0)"
          style="font-size:.85rem; text-transform:none; font-weight:300"
          data-popdismiss="false"
        >
          <label
            class="checkbox checkbox-square"
            data-popdismiss="false"
          >
            <input
              id="activity-select-all-input"
              type="checkbox"
              data-popdismiss="false"
              :checked="userEngagementData.activityData.length === checkedActivityIds.length"
              @click="$emit('toggle-all')"
            />
            <span data-popdismiss="false"></span>
            <p class="pl-2 mb-0" style="font-size:.9rem; font-weight:400" data-popdismiss="false">
              {{ $t('TABLES.USER_ENGAGEMENT_DROPDOWN.SHOW_ALL_ACTIVITIES') }}
            </p>
          </label>
        </a>
      </template>

      <div data-popdismiss="false" style="max-height:300px">
        <vuescroll style="height: 300px">
          <template v-for="(activity, a) in userEngagementData.activityData">
            <a
              :key="a"
              class="dropdown-item px-4 py-2"
              href="javascript:void(0)"
              style="font-size:.85rem; text-transform:none; font-weight:300"
              data-popdismiss="false"
            >
              <label
                class="checkbox checkbox-square"
                data-popdismiss="false"
              >
                <input
                  :id="a"
                  type="checkbox"
                  data-popdismiss="false"
                  :checked="checkedActivityIds.includes(activity.id)"
                  @click="$emit('toggle-activity',activity.id)"
                />
                <span data-popdismiss="false"></span>
                <p class="pl-2 mb-0" style="font-size:.9rem" data-popdismiss="false">
                  {{ activity.shortened_activity_title }}
                </p>
              </label>
            </a>
          </template>
        </vuescroll>
      </div>

      <div
        v-if="checkedActivityIds.length > 0"
        class="d-flex align-items-center justify-content-center pr-4 pl-0 pt-2 mt-4"
      >
        <a  
          href="javascript:void(0)"
          @click="$emit('clear-activities')"
          id="clear-activities"
          style="font-size:.9rem"
          data-popdismiss="false"
        >
          {{ $t('REVIEW.TEXT_ANALYSIS.RESET') }}
        </a>        
      </div>
    </b-popover>
  </OnClickOutside>
</template>

<script>
import vuescroll from 'vuescroll';
import { OnClickOutside } from '@vueuse/components'

export default {
  name: "ActivitiesHeader",

  props: {
    checkedActivityIds: { type: Array, required: true },
    userEngagementData: { type: Object, required: true },
    showActivitySelect: { type: Boolean, required: true }
  },

  components: {
    vuescroll,
    OnClickOutside
  },

  methods: {
    closePopover: function(e) {
      if (e.srcElement.dataset.popdismiss == undefined || (e.srcElement.dataset.popdismiss != undefined && e.srcElement.dataset.popdismiss != 'false')) {
        this.$emit('toggle-activity-select',false);
      }
    }
  }
}
</script>