<template>
  <div class="col-xl-12 px-0">
    <div
      id="review-filter-container"
      v-if="loadingComplete && !error.active"
      class="card card-custom card-stretch gutter-b"
    >
      <UserEngagementFilter
        :filterService="filterService"
        @on-data-change="fetchUserEngagement()"
      >
        <template v-slot:actions>
          <UserEngagementToolbar
            :checkedUsers="checked_users"
            :users="userEngagementData.userData"
            :segmentations="projectSegmentations"
            @users_changed="fetchUserEngagement()"
            class="mr-5"
          >
          </UserEngagementToolbar>
        </template>
      </UserEngagementFilter>
    </div>

    <div
      class="row mx-0"
      :class="{ 'mt-25' : loadingComplete }"
    >
      <UserEngagementTable
        :error="error"
        :loadingComplete="(loadingComplete === true && pageLoader.reloading === false) ? true : false"
        :userEngagementData="userEngagementData"
        @user_checked="checked_users.push($event)"
        @user_unchecked="uncheckUser($event)"
      />
    </div>
  </div>
</template>

<script>
import {
  mapGetters,
  mapMutations
} from "vuex";

import LoadingCompleteHelper from "@/helpers/LoadingCompleteHelper";
import UserEngagementFilter from "@/view/components/ts-filter/Filter.vue";
import filterConfig from "@/core/config/ts-filter/FilterProjectUsers.json";
import Filter from "@/core/services/FilterService";
import UserEngagementToolbar from "@/view/pages/project_management/review/user_engagement/Toolbar";

import UserEngagementTable from "@/view/components/tables/UserEngagementTable";

import { POPULATE_PROJECT_SEGMENTATION_LIST } from "@/core/services/store/project/project_segmentations.module";

export default {
  name: "UserEngagement",

  data() {
    return {
      pageLoader: {
        componentsRequired: 2,
        componentsCompleted: 0,
        reloading: false
      },
      error: {
        active: false,
        message: ""
      },
      filterService: new Filter(
        filterConfig,
        {
          x_filter_name: "",
          x_filter_sources: [],
          x_filter_status: [1],
          x_filter_segmentFilterOption: 1,
          x_filter_segments: [],
          x_filter_participation: []
        }
      ),
      userEngagementData: null,
      checked_users: []
    }
  },

  components: {
    UserEngagementFilter,
    UserEngagementToolbar,
    UserEngagementTable
  },

  mounted() {
    this.fetchProjectSegmentations();
    this.fetchUserEngagement();

    this.filterService.options.systemProjectUserSamples = this.systemProjectUserSamples;
  },

  methods: {
    fetchProjectSegmentations: function() {
      let payload = {
        projectId: this.projectInfo.id,
        params: {
          projectSegments: 'with',
          paginate: false
        }
      };
      this.$store
        .dispatch(POPULATE_PROJECT_SEGMENTATION_LIST, payload)
        .then(() => {
          this.pageLoader.componentsCompleted++;
          this.filterService.options.projectSegmentationList = this.projectSegmentations;
        })
        .catch(() => {
          this.error.active = true;
          this.error.message =
            "We're having some issues retrieving the segmentation list, please check back later or contact the helpdesk";
        });
    },
    fetchUserEngagement: function() {
      this.pageLoader.reloading = true;

      let payload = {
        route: `api/v1/projects/${this.projectInfo.id}/project_user_engagements`,
        params: {
          filter: this.filterService.data
        }
      }

      this.$store.dispatch(GENERIC_RESOURCE, payload)
        .then((res) => {
          this.userEngagementData = res;
          this.pageLoader.componentsCompleted += 1;
        }).finally(() => {
          this.pageLoader.reloading = false;
          this.checked_users = [];
        })
    },
    uncheckUser: function(value) {
      const index = this.checked_users.indexOf(value);
      this.checked_users.splice(index, 1);
    },
    ...mapMutations({
      setProjectSegmentationList: "setProjectSegmentationList",
      setProjectSegmentationErrors: "setProjectSegmentationErrors"
    })
  },

  computed: {
    ...mapGetters([
      "projectInfo",
      "projectSegmentations",
      "systemProjectUserSamples"
    ]),
    loadingComplete: function() {
      return LoadingCompleteHelper.loadingComplete(this.pageLoader, this.error);
    }
  }
}
</script>