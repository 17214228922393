<template>
  <div
    class="ts-filter py-3 pr-3 d-flex align-items-center justify-content-between"
  >
    <div class="ts-filter-fields d-flex justify-content-start align-items-center">
      <span
        v-if="filterService.title != undefined && filterService.title != null"
        class="fw-bold mr-2"
        style="margin-left:15px; margin-bottom:2px"
      >
        {{ filterService.title }}
      </span>

      <template v-for="(field, f) in filterService.filterConfig.fields">
        <component
          :key="f"
          :is="field.type"
          :filterService="filterService"
          v-bind="field"
          style="margin-left: 15px; height: 100%"
        />
      </template>

      <button
        class="btn btn-sm ts-filter-reset"
        style="margin: 0px 12px; height:35px !important; margin-bottom:2px !important"
        @click="onReset"
      >
        {{ $t('REVIEW.TEXT_ANALYSIS.RESET') }}
      </button>

      <button
        class="btn btn-sm ts-filter-submit"
        style="height:35px !important; margin-bottom:2px !important"
        @click="$emit('on-data-change')"
      >
        {{ $t('REVIEW.STREAM.FILTER_APPLY') }}
      </button>
    </div>

    <div class="ts-filter-actions d-flex justify-content-end">
      <slot name="actions" />
    </div>

  </div>
</template>

<script>
import Filter from "@/core/services/FilterService";

import TextInput from "./filter-elements/TextInput.vue";
import ToggleInput from "./filter-elements/ToggleInput.vue";
import TreeSelect from "./filter-elements/TreeSelect.vue";

export default {
  emits: ['on-data-change'],

  name: "TsFilter",

  props: {
    filterService: { type: Filter, required: true }
  },

  components: {
    TextInput,
    ToggleInput,
    TreeSelect
  },

  methods: {
    onReset: function() {
      this.filterService.resetData();
      this.$emit('on-data-change');
    }
  }
}
</script>